import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Heading, Button, Link, Text } from 'src/components/Base'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import {
  cHero,
  cHeroProjektDetail,
  cProjectDetailDesktop,
  cProjectDetail,
  cLarge,
  cContainer,
  cLabel,
  cHeading,
  cButton,
  cTitle,
  cDate,
  cLocation,
  cHasIntroCard,
  cHeroOverlay,
  cJobHero,
  cJobHeroMobile,
} from './styles.module.scss'

const propTypes = {
  imageData: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  dateTitle: PropTypes.string,
  date: PropTypes.string,
  locationTitle: PropTypes.string,
  location: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonIsInternal: PropTypes.boolean,
  projectDetail: PropTypes.node,
  hasOverlay: PropTypes.bool,
}

const defaultProps = {
  isLarge: false,
  classNames: undefined,
  label: undefined,
  dateTitle: 'Termin: ',
  date: undefined,
  locationTitle: 'Ort: ',
  location: undefined,
  buttonText: undefined,
  buttonLink: undefined,
  buttonIsInternal: false,
  projectDetail: undefined,
  hasOverlay: true,
}

const Hero = ({
  hasIntroCard,
  imageData,
  heading,
  isLarge,
  className,
  label,
  dateTitle,
  date,
  locationTitle,
  location,
  buttonText,
  buttonLink,
  buttonIsInternal,
  projectDetail,
  hasOverlay,
  jobHero,
  jobHeroMobile,
  branchColor,
}) => {
  const heroClasses = classNames({
    [cHero]: true,
    [cHeroProjektDetail]: !!projectDetail,
    [cLarge]: isLarge,
    [className]: !!className,
    [cJobHero]: jobHero,
    [cJobHeroMobile]: jobHeroMobile,
  })

  const headingClasses = classNames({
    [cHeading]: true,
    [cHasIntroCard]: hasIntroCard,
  })

  const image = getImage(imageData)
  const bgImage = convertToBgImage(image)

  return (
    <div className={hasOverlay && cHeroOverlay}>
      <BackgroundImage
        Tag="section"
        className={heroClasses}
        preserveStackingContext
        {...bgImage}
      >
        <Container className={cContainer}>
          {!!label && <span className={cLabel}>{label}</span>}
          <Heading as="h1" className={headingClasses} branchColor={branchColor}>
            {heading}
          </Heading>
          {!!date && (
            <div className="mt-2">
              <Text as="span" className={cTitle}>
                {dateTitle}
              </Text>
              <Text as="span" className={cDate}>
                {date}
              </Text>
            </div>
          )}
          {!!location && (
            <div>
              <Text as="span" className={cTitle}>
                {locationTitle}
              </Text>
              <Text as="span" className={cLocation}>
                {location}
              </Text>
            </div>
          )}
          {!!buttonText && !!buttonLink && !buttonIsInternal && (
            <Link to={buttonLink}>
              <Button color="cta" className={cButton}>
                {buttonText}
              </Button>
            </Link>
          )}
          {!!buttonText && !!buttonLink && buttonIsInternal && (
            <Button as="a" href={buttonLink} color="cta" className={cButton}>
              {buttonText}
            </Button>
          )}
          {!!projectDetail && (
            <div className={cProjectDetailDesktop}>{projectDetail}</div>
          )}
        </Container>
        {!!projectDetail && (
          <div className={cProjectDetail}>{projectDetail}</div>
        )}
      </BackgroundImage>
    </div>
  )
}

Hero.propTypes = propTypes
Hero.defaultProps = defaultProps

export default Hero
