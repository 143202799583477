import React from 'react'
import Seo from 'src/components/Seo'
import Hero from 'src/sections/Hero'
import { graphql } from 'gatsby'

const HeroExamplePage = ({ data }) => (
  <>
    <Seo title="Hero Example" />

    <Hero
      imageData={data.wpPage.atspHero.heroImage.localFile}
      heading="SAP Prozesse und Systeme sind *unsere Spezialität*"
      isLarge
    />

    <Hero
      imageData={data.wpPage.atspHero.heroImage.localFile}
      heading="ATSP Healthcare *Task Managment*"
    />

    <Hero
      imageData={data.wpPage.atspHero.heroImage.localFile}
      heading="Kunde: Normua"
      label="Test Lorem Ipsum Test Test Test"
      projectDetail={
        <div
          style={{
            maxWidth: '355px',
            height: '255px',
            backgroundColor: 'white',
          }}
        >
          Example Node
        </div>
      }
    />

    <Hero
      imageData={data.wpPage.atspHero.heroImage.localFile}
      heading="ATSP Healthcare *Task Managment*"
      label="Webinar"
      date="25.11.2020, 10 Uhr, Dauer 30min"
      location="Webinar - Webex"
      buttonText="Jetzt anmelden"
      buttonLink="/page-2/"
    />
  </>
)

export const pageQuery = graphql`
  query ExampleHeroById {
    wpPage(id: { eq: "cG9zdDozMA==" }) {
      atspHero {
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
  }
`

export default HeroExamplePage
