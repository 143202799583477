// extracted by mini-css-extract-plugin
export var cButton = "styles-module--cButton--3084f";
export var cContainer = "styles-module--cContainer--3635d";
export var cDate = "styles-module--cDate--08d97";
export var cHasIntroCard = "styles-module--c-has-intro-card--31ad9";
export var cHeading = "styles-module--cHeading--9c786";
export var cHero = "styles-module--cHero--e54e9";
export var cHeroOverlay = "styles-module--cHeroOverlay--916f8";
export var cHeroProjektDetail = "styles-module--cHeroProjektDetail--e6bcf";
export var cJobHero = "styles-module--c-job-hero--6e8a3";
export var cJobHeroMobile = "styles-module--c-job-hero-mobile--bc694";
export var cLabel = "styles-module--cLabel--52ed8";
export var cLarge = "styles-module--cLarge--3b24e";
export var cLocation = "styles-module--cLocation--4e7c8";
export var cProjectDetail = "styles-module--cProjectDetail--3c516";
export var cProjectDetailDesktop = "styles-module--cProjectDetailDesktop--2e9dc";
export var cTitle = "styles-module--cTitle--db3fe";